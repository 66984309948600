import "../../styles/Pages/Products.scss"

const Products = () => {
    return (
        <div className="products">
            <div className="summary">
                <h1>Products</h1>
                <h2>Coming soon!</h2>
            </div>
        </div>
    )
}

export default Products;
